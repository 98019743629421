.container {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: var(--gap-column);
  width: 100%;
  max-width: var(--max-width);
}

.container[data-wide='true'] {
  max-width: var(--max-width-wide);
}
