.frame {
  --shadow: 0 15px 25px rgb(0 0 0 / 15%), 0 5px 10px rgb(0 0 0 / 5%);

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: var(--z-middle);
  border-radius: var(--border-radius-1);
  box-shadow: var(--shadow);
  background: var(--color-brand);

  /* background: var(--color-background-25); */
  max-width: min(25rem, 25vw);
  min-height: 100%;
  aspect-ratio: 1/2;
}

.frame::before,
.frame::after {
  position: absolute;
  top: 0;
  transform: translateY(-100%);
  z-index: var(--z-back);
  box-shadow: var(--shadow);
  background-color: var(--color-text-15);
  width: 1px;
  height: var(--gap);
  content: '';
}

.frame::before {
  left: var(--spacing-5);
}

.frame::after {
  right: var(--spacing-5);
}

.image {
  /* todo */
}

:global(:root.dark) .frame {
  filter: brightness(0.85);
  transition: var(--timing-fast) filter var(--ease-in-out);
}
