.section {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  margin: 0;
  width: 100%;
  height: 100%;
}

.list {
  --height: 70vh;
  --gap: 15vh;

  display: flex;
  flex-direction: column;
  gap: var(--gap);
  margin: 0 auto;
  padding: var(--gap) 0;
  max-width: var(--max-width-wide);
  list-style: none;
}

.list li {
  box-sizing: border-box;
  display: flex;
  height: var(--height);
}
