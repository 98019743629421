.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--gap-m);
}

.row {
  display: flex;
  flex-direction: column;
  gap: var(--gap-text);
  justify-content: space-between;
  width: 100%;
  text-align: center;
}

@media screen and (width >= 768px) {
  .row {
    flex-direction: row;
    text-align: unset;
  }
}
