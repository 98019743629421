.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  transition: transform 0.65s cubic-bezier(0.45, 0.02, 0.09, 0.98) 0s;
  z-index: 0;
  background: var(--color-text);
  width: 100%;
  height: max(450px, 50vh);
  overflow: hidden;
}

.blocks {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  transform: scale(0.9) translateY(-120%);
  transition: transform 0.65s cubic-bezier(0.45, 0.02, 0.09, 0.98) 0s;
  width: 100%;
  height: 100%;
}

.block {
  background: var(--color-background);
  width: 20%;
  height: 50%;
}

:global(#page-wrapper[data-search-open='true']) ~ .footer {
  transform: translateY(0);
}

:global(#page-wrapper[data-search-open='true']) ~ .footer .blocks {
  transform: scale(1) translateY(0);
}
