.underline {
  --underline-size: 2px;

  display: inline;
  transition: background-size var(--timing-fast);
  background: linear-gradient(var(--color-text), var(--color-text)) 0 100%;
  background-repeat: no-repeat;
  background-size: 0 var(--underline-size);
}

.order {
  color: var(--color-text-25);
  font-size: var(--font-size-2);
  font-style: italic;
}

.link {
  outline: none;
  text-decoration: none;
}

.link:hover .underline,
.link:focus-visible .underline {
  background-size: 100% var(--underline-size);
}

.article {
  display: flex;
  gap: var(--gap-xl);
  align-items: center;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--gap-text);
  justify-content: flex-end;
}

.meta {
  display: flex;
  color: var(--color-text-25);
  font-size: var(--font-size-2);
}

.footer {
  display: flex;
  justify-content: flex-end;
  color: var(--color-text-25);
  font-size: var(--font-size-2);
}

.title {
  line-height: var(--line-height-heading);
  font-size: var(--font-size-5);
}

.button {
  display: flex;
  position: relative;
  transition: var(--timing-faster) outline var(--ease-out);
  margin: var(--gap-l) auto;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: var(--gap-s) var(--gap-m);
  line-height: var(--line-height-heading);
  color: var(--color-text);
  font-size: var(--font-size-2);
}

.button::after {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform var(--timing-fast) var(--ease-out);
  background-color: var(--color-text);
  width: 100%;
  height: 2px;
  content: '';
}

.link:hover .button::after,
.link:focus-visible .button::after {
  transform: scaleX(1);
}
